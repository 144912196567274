* {
  margin: 0;
  padding: 0;
}

.card {
  border: none;
}

.aaf-logo {
  max-width: 300px !important;
}

.social-icons {
  margin-right: 10px;
  font-weight: bolder;
  cursor: pointer !important;
  color: rgb(10, 10, 10);
}

.animate__animated .animate__bounceIn {
  --animate-delay: 2s !important;
}

.gap-1 {
  height: 20px;
}

.nav-link {
  margin: 10px !important;
  color: black !important;
  font-weight: bolder;
}

.nav-link:hover {
  color: rgb(255 139 124) !important;
}


/* index styling */

.index_card {
  border: none !important;
  margin: 100px;
  box-shadow: none;
}


/* about styling */

.about_main {
  background-size: cover !important;
  width: 100% !important;
}

.about_main2 {
  padding: 70px !important;
}


/* gallery styling */

h1.gallery_heading {
  text-align: center;
}

.gallery-card {
  border: none;
  box-shadow: none;
}

.card-body {
  text-align: center;
}

.card-img-top{
  height: 10rem !important;
  object-fit: cover;
}

.height_set{
  height: 12rem !important;
  object-fit: cover;
}


/* january styling */

.item {
  transition: 5s ease-in-out;
  /* height: 300px; */
}

.item:hover {
  filter: brightness(80s);
}


/* contact styling */

.contact_container {
  text-align: center;
}

.contact_form {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.button_submit {
  padding: 10px;
}

.submit_button {
  width: 100px;
  padding: 7px;
  border: 1px solid black;
  border-radius: 25px;
  background-color: rgb(255 139 124) !important;
}


/* projects styling */

.heading {
  text-align: center !important;
}


/* donate.php */

.donateContainer {
  text-align: center;
  background-color: rgb(10, 10, 10);
}

.donate-btn {
  background-color: rgb(255 139 124);
  font-size: x-large;
  font-weight: bold;
  color: rgb(10, 10, 10);
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.donateFormContainer {
  text-align: center;
}

.donateForm {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}